import React, { useRef, useState, useEffect } from 'react';

import moment from 'moment';
import update from 'immutability-helper';

import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import Card from 'classes/Card.js';
import CommLink from 'classes/CommLink.js';
import { CommLinkDetails } from 'managers/OmniShield.js';
import Content from 'managers/Content.js';
import { DealershipDetails } from 'managers/Dealerships.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import { Line } from 'react-chartjs-2';
import { Map } from 'views/MapElements.js';
import Panel from 'structure/Panel.js';
import Product from 'classes/Product.js';
import ProtectionFilters from 'views/ProtectionFilters.js';
import Request from 'files/Request.js';
import SystemEventsList from 'views/SystemEventsList.js';
import { TableListHeader, TableListRow } from 'views/TableList.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import Utils from 'files/Utils.js';
import Views, { AltBadge } from 'views/Main.js';

// Panels
export const Cards = ({ index, options, utils }) => {

    const panelID = 'cards';
    const limit = 10;
    const offset = useRef(0);
    const searchPropsRef = useRef({});
    const sorting = useRef({ sort_key: 'date', sort_type: Content.sorting.type.descending });

    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [searchProps, setSearchProps] = useState(null);

    const onCardClick = card => {
        utils.layer.open({
            id: `card_details_${card.id}`,
            abstract: Abstract.create({
                type: 'card',
                object: card
            }),
            Component: CardDetails
        });
    }

    const onDownloadProtections = async () => {
        utils.alert.show({
            title: 'Download Your Protections',
            message: `It may take a couple of minutes to prepare your protections. We'll let you know once your download is ready to go. Please keep your browser window open while we prepare your document.`,
            onClick: async () => {
                try {
                    await Utils.sleep(0.25);
                    await Request.post(utils, '/cards/', {
                        download: true,
                        search_props: searchPropsRef.current,
                        type: 'advanced_search',
                        ...sorting
                    });
        
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue preparing your protections. ${e.message || 'An unknown error occurred'}`
                    });
                }
            }
        });
    }

    const onNewCard = () => {

        // prepare nwe card object
        let card = Card.new();
        card.dealership = utils.dealership.get();

        // open layer for card editing
        utils.layer.open({
            id: 'new_card',
            abstract: Abstract.create({
                type: 'card',
                object: card
            }),
            Component: AddEditCard.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onPrintCards = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { cards } = await Request.post(utils, '/cards/', {
                    type: 'advanced_search',
                    search_props: searchPropsRef.current,
                    ...sorting,
                    ...props
                });

                setLoading(false);
                resolve(cards.map(card => Card.create(card)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        });
    }

    const onTextChange = text => {
        onUpdateSearchProps('text', text);
    }

    const onUpdateCard = data => {
        try {
            setCards(cards => {
                return cards.map(card => {
                    return card.id === data.card.id ? Card.create(data.card) : card;
                })
            })
        } catch(e) {
            console.log(e.message);
        }
    }

    const onUpdateSearchProps = (key, value) => {
        offset.current = 0;
        setSearchProps(props => {
            return update(props || {}, {
                [key]: {
                    $set: value
                }
            });
        });
    }

    const getButtons = () => {
        return [{
            key: 'graci_support_content',
            items: [{
                id: 'aft.new_protection',
                title: 'Creating a Protection'
            },{
                id: 'aft.protections',
                title: 'Managing Protections'
            }]
        },{
            key: 'new',
            title: 'New Protection',
            style: 'default',
            onClick: onNewCard
        },{
            key: 'download',
            title: 'Download',
            style: 'secondary',
            onClick: onDownloadProtections
        }/*,{
            key: 'selecting',
            title: `${selecting ? 'Select One' : 'Select Batches'}`,
            style: selecting ? 'cancel' : 'secondary',
            onClick: () => setSelecting(val => !val),
            visible: cards.length > 0
        }*/];
    }

    const getContent = () => {
        if(cards.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'No protections were found for your dealership',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {cards.map((card, index) => {
                        return getFields(card, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (card, index) => {

        let target = card || {};
        let fields = [{
            key: 'customer',
            title: 'Customer',
            padding: false,
            value: (
                Views.entry({
                    key: index,
                    title: target.customer ? target.customer.full_name : null,
                    subTitle: target.sold_by ? `Sold by ${target.sold_by.full_name}` : 'Seller not available',
                    icon: {
                        path: 'images/cards-icon-clear-small.png'
                    },
                    bottomBorder: false,
                    style: {
                        padding: '8px 12px 8px 12px',
                        textAlign: 'left'
                    }
                })
            )
        },{
            key: 'total_units',
            title: 'Total Units',
            value: (
                <div style={{
                    display: 'inline-block'
                }}>
                    <AltBadge content={getTotalUnitsBadge(target)} />
                </div>
            )
        },{
            key: 'locality',
            title: 'City',
            value: target.customer && target.customer.address ? target.customer.address.locality : null
        },{
            key: 'administrative_area_level_1',
            title: 'State',
            value: target.customer && target.customer.address ? target.customer.address.administrative_area_level_1 : null
        },{
            key: 'date',
            title: 'Purchase Date',
            value: target.date ? Utils.formatProtectionDate(target.date) : null
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!card) {
            return (
                <TableListHeader
                fields={fields}
                onChange={props => {
                    sorting.current = props;
                    fetchCards();
                }} 
                value={sorting.current} />
            )
        }
        return (
            <TableListRow
            key={index}
            values={fields}
            lastItem={index === cards.length - 1}
            onClick={onCardClick.bind(this, card)} />
        )
    }

    const getFilterContent = () => {
        return (
            <ProtectionFilters
            onChange={onUpdateSearchProps}
            searchProps={searchProps}
            utils={utils} />
        )
    }

    const getPrintProps = () => {
        return {
            onFetch: onPrintCards,
            onRenderItem: item => ({
                customer: item.sold_by_user ? `Sold by ${item.sold_by_user.full_name}` : 'Seller not available',
                dealership: item.dealership ? item.dealership.name : null,
                total_units: item.total_units,
                locality: item.address ? item.address.locality : null,
                administrative_area_level_1: item.address ? item.address.administrative_area_level_1 : null,
                date: item.date ? Utils.formatProtectionDate(item.date) : null
            }),
            headers: [{
                key: 'customer',
                title: 'Customer'
            },{
                key: 'dealership',
                title: 'Dealership'
            },{
                key: 'total_units',
                title: 'Total Units'
            },{
                key: 'locality',
                title: 'City'
            },{
                key: 'administrative_area_level_1',
                title: 'State'
            },{
                key: 'date',
                title: 'Purchase Date'
            }]
        }
    }

    const connectToSockets = async () => {
        try {
            await utils.sockets.on('aft', 'cards', 'on_new_card', fetchCards);
            await utils.sockets.on('aft', 'cards', 'on_update_card', onUpdateCard);
        } catch(e) {
            console.error(e.message);
        }
    }

    const disconnectFromSockets = async () => {
        try {
            await utils.sockets.off('aft', 'cards', 'on_new_card', fetchCards);
            await utils.sockets.off('aft', 'cards', 'on_update_card', onUpdateCard);
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchCards = async () => {
        try {
            setLoading(true);
            let { cards, paging } = await Request.post(utils, '/cards/', {
                limit: limit,
                offset: offset.current,
                search_props: searchPropsRef.current,
                type: 'advanced_search',
                ...sorting.current
            });

            setLoading(false);
            setPaging(paging);
            setCards(cards.map(card => Card.create(card)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the protections list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        searchPropsRef.current = searchProps;
        fetchCards();
    }, [searchProps]);

    useEffect(() => {

        connectToSockets();
        utils.events.on(panelID, 'dealership_change', () => {
            fetchCards();
        });
        utils.content.subscribe(panelID, 'card', {
            onFetch: () => {
                fetchCards();
            },
            onUpdate: abstract => {
                setCards(cards => {
                    return cards.map(card => {
                        return card.id === abstract.getID() ? abstract.object : card;
                    })
                });
            }
        });
        return () => {
            disconnectFromSockets();
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchCards);
        }
    }, [])

    return (
        <Panel
        panelID={panelID}
        name={'Protections'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            buttons: getButtons(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    setLoading(true);
                    fetchCards();
                }
            },
            search: {
                placeholder: 'Search by id, name, address, city, or state...',
                onChange: onTextChange,
                filters: {
                    content: getFilterContent()
                },
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const CardLocations = ({ index, options, utils }) => {

    const panelID = 'card_locations';
    const searchPropsRef = useRef({});

    const [loading, setLoading] = useState(false);
    const [locationData, setLocationData] = useState(null);
    const [region, setRegion] = useState(null);
    const [searchProps, setSearchProps] = useState({});

    const onCardClick = async props => {
        try {
            setLoading(true);
            let card = await Card.get(utils, props.id);

            setLoading(false);
            utils.layer.open({
                id: `card_details_${card.id}`,
                abstract: Abstract.create({
                    type: 'card',
                    object: card
                }),
                Component: CardDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this Protection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateSearchProps = (key, value) => {
        setSearchProps(props => {
            return update(props || {}, {
                [key]: {
                    $set: value
                }
            });
        });
    }

    const getButtons = () => {
        return [{
            key: 'graci_support_content',
            items: ['aft.protections_heat_map']
        }]
    }

    const getFeatures = () => {
        if(!locationData) {
            return null;
        }
        return {
            id: 'card_locations',
            data: locationData,
            icons: [{
                key: 'location-icon-grey',
                path: 'images/location-icon-grey.png'
            }],
            layers: [{
                id: 'card_locations',
                layout: {
                    'icon-size': 0.2,
                    'icon-anchor': 'center',
                    'icon-image': 'location-icon-grey',
                    'icon-allow-overlap': true
                },
                onClick: onCardClick,
                onHover: properties => {
                    return {
                        subTitle: properties.address,
                        title: properties.name
                    }
                },
                paint: {
                    'icon-color': [ 'get', 'color' ]
                },
                source: 'card_locations',
                type: 'symbol'
            }]
        }
    }

    const fetchLocations = async () => {
        try {
            setLoading(true);
            let { data, region } = await Request.get(utils, '/cards/', {
                search_props: searchPropsRef.current,
                type: 'locations'
            });

            setLoading(false);
            setRegion(region);
            setLocationData(data);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the location map data. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        searchPropsRef.current = searchProps;
        fetchLocations();
    }, [searchProps]);

    useEffect(() => {
        fetchLocations();
        utils.events.on(panelID, 'dealership_change', () => {
            setSearchProps({});
            fetchLocations();
        });
        utils.content.subscribe(panelID, 'card', {
            onFetch: fetchLocations,
            onUpdate: fetchLocations
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change');
        }
    }, []);

    return (
        <Panel
        id={panelID}
        name={'Protection Locations'}
        index={index}
        utils={utils}
        options={{
            ...options,
            buttons: getButtons(),
            filters: {
                content: (
                    <ProtectionFilters
                    onChange={onUpdateSearchProps}
                    requireLocation={true}
                    searchProps={searchProps}
                    utils={utils} />
                )
            },
            loading: loading
        }}>
            <Map
            features={getFeatures()}
            isScrollEnabled={true}
            isZoomEnabled={true}
            isRotationEnabled={true}
            region={region}
            style={{
                width: '100%',
                height: 350
            }}/>
        </Panel>
    )
}

export const CardPerformance = ({ index, options, utils }) => {

    const panelID = `card_performance`;
    const chart = useRef(null);

    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [startDate, setStartDate] = useState(Utils.ytd.start_date());
    const [endDate, setEndDate] = useState(Utils.ytd.end_date());
    const [tooltip, setTooltip] = useState(null);

    const onPrintChartData = async props => {
        return new Promise(resolve => {
            let data = chartData.datasets[0].data.map((entry, index) => ({
                date: chartData.labels[index],
                total_units: entry
            }));
            resolve(data);
        })
    }

    const getChart = () => {
        if(!chartData || chartData.labels.length < 3) {
            return null;
        }
        let { datasets, labels } = chartData;
        return (
            <div style={{
                padding: 15,
                width: '100%'
            }}>
                <Line
                ref={chart}
                width={500}
                height={200}
                data={{
                    labels: labels,
                    datasets: datasets
                }}
                options={{
                    title: { display: false },
                    legend: { display: false },
                    responsive: true,
                    maintainAspectRatio: true,
                    interaction: {
                        mode: 'dataset'
                    },
                    tooltips: {
                        enabled: false,
                        custom: (model, data) => {

                            let { caretX, caretY, dataPoints, opacity } = model;
                            if(opacity === 0) {
                                setTooltip(null);
                                return;
                            }
                            let position = chart.current.chartInstance.canvas.getBoundingClientRect();
                            setTooltip({
                                top: caretY,
                                left: caretX,
                                content: dataPoints.map(({ datasetIndex, index }) => {
                                    let dataset = chartData.datasets[datasetIndex];
                                    let val = dataset.data[index];
                                    return {
                                        title: labels[index],
                                        subTitle: `${Utils.softNumberFormat(val)} ${val === 1 ? 'unit' : 'units'} sold`
                                    }
                                })
                            });
                        }
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                color: Appearance.colors.transparent,
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 20
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                color: Appearance.colors.transparent,
                                display: false
                            },
                            ticks: {
                                beginAtZero: true,
                                callback: (value, index, values) => {
                                    return Utils.softNumberFormat(value);
                                }
                            }
                        }]
                    }
                }} />
                {tooltip && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        padding: '8px 12px 8px 12px',
                        top: tooltip.top,
                        left: tooltip.left,
                        zIndex: 8500,
                        backgroundColor: Appearance.colors.layerBackground(),
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        {tooltip.content.map((entry, index, entries) => {
                            return (
                                <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: index !== entries.length - 1 ? 12 : 0
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <span style={{
                                            ...Appearance.textStyles.title()
                                        }}>{entry.title}</span>
                                        <span style={{
                                            ...Appearance.textStyles.subTitle()
                                        }}>{entry.subTitle}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        )
    }

    const getPrintProps = () => {

        let headers = [{
            key: 'date',
            title: 'Date'
        },{
            key: 'total_units',
            title: 'Total Units Sold'
        }];

        return {
            headers: headers,
            onFetch: onPrintChartData,
            onRenderItem: (item, index, items) => ({
                date: item.date ? moment(item.date).format('MMMM YYYY') : null,
                total_units: item.total_units
            })
        }
    }

    const fetchPerformance = async () => {
        try {
            let { datasets, labels } = await Request.get(utils, '/cards/', {
                type: 'performance',
                start_date: startDate.format('YYYY-MM-DD'),
                end_date: endDate.format('YYYY-MM-DD')
            })

            setLoading(false);
            setChartData({
                labels: labels.map(label => moment(label, 'YYYY-MM').format('MMM YYYY')),
                datasets: datasets.map(dataset => ({
                    label: dataset.name,
                    fill: false,
                    data: dataset.data,
                    pointStyle: 'circle',
                    borderWidth: 2,
                    borderColor: Appearance.colors.grey(),
                    pointRadius: 5,
                    pointBorderWidth: 3,
                    pointBackgroundColor: Appearance.colors.grey(),
                    pointBorderColor: Appearance.colors.grey()
                }))
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for the protections graph. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchPerformance();
    }, [startDate, endDate]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchPerformance);
        return () => {
            utils.events.off(panelID, 'dealership_change', fetchPerformance);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Product Sales'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            print: getPrintProps()
        }}
        buttons={[{
            key: 'new',
            title: 'New Product',
            style: 'default'
        }]}>
            <div style={{
                marginBottom: 15
            }}>
                <DualDatePickerField
                utils={utils}
                selectedStartDate={startDate}
                selectedEndDate={endDate}
                onStartDateChange={date => setStartDate(date)}
                onEndDateChange={date => setEndDate(date)} />
            </div>
            <div
            className={chartData && chartData.labels.length < 3 ? 'pinstripes' : ''}
            onClick={() => setTooltip(null)}
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                minHeight: 450,
                borderRadius: 10,
                border: `1px solid ${Appearance.colors.divider()}`,
                ...chartData && chartData.labels.length < 3 && {
                    backgroundColor: Appearance.colors.background()
                }
            }}>
                {getChart()}
                {chartData && chartData.labels.length < 3 && (
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '12px 16px 12px 16px',
                        textAlign: 'center',
                        maxWidth: 'calc(100% - 30px)'
                    }}>
                        <img
                        src={'images/no-data-found-icon.png'}
                        style={{
                            width: 50,
                            height: 50,
                            marginBottom: 8
                        }} />
                        <span style={{
                            ...Appearance.textStyles.title()
                        }}>{'Too Few Sales Found'}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            whiteSpace: 'normal'
                        }}>{'Product sale overviews will show here when available'}</span>
                    </div>
                )}
            </div>
        </Panel>
    )
}

// Layers
export const AddEditCard = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_card' : `edit_card_${abstract.getID()}`;
    const commLink = useRef(abstract.object.comm_link || {});

    const [card, setCard] = useState(null);
    const [dealershipRegistrations, setDealershipRegistrations] = useState([]); 
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);

    const onAutofillSensorTotals = (commLink, products) => {

        utils.alert.show({
            title: 'Network Found',
            message: `We found a home safe network${commLink.name && commLink.name.length > 0 ? ` named "${commLink.name}"` : ''} that matches the serial number you provided. It looks like there are some sensors on the network that you have not added to this protection. Would you like us to update the sensor totals on this protection?`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'No Thanks',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onUpdateTarget({
                        units: products.reduce((object, product) => {
                            object[product.key] = product.sensor_totals || (card.units ? card.units[product.key] : 0);
                            return object;
                        }, {})
                    })
                    return;
                }
            }
        })
    }

    const onDoneClick = async () => {
        try {

            // set loading flag and validate required fields
            setLoading('done');
            await validateRequiredFields(getFields);

            // request confirmation that protection is being submitted to another dealership if applicable
            if(card.dealership.id !== utils.dealership.get().id) {
                await requestDealershipMismatchConfirmation();
            }

            // submit request to server
            isNewTarget ? await abstract.object.submit(utils) : await abstract.object.update(utils);

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The protection for ${abstract.object.customer.first_name} ${abstract.object.customer.last_name} has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            if(e.message !== 'user_cancelled') {
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this protection. ${e.message || 'An unknown error occurred'}`
                });
            }
        }
    }

    const onUpdateCommLink = async (key, text) => {
        try {

            // prepare credentials for comm link
            commLink.current[key] = key === 'security_key' ? text && text.toUpperCase() : text;

            // prevent moving forward if serial number is incomplete
            if(!commLink.current.serial_number || !commLink.current.security_key || commLink.current.security_key.length < 5 || commLink.current.serial_number.length < 5) {
                console.log('incomplete comm link credentials');
                return;
            }

            // fetch sensor details for system
            setLoading(key);
            let { comm_link, sensors } = await Request.get(utils, '/omnishield/', {
                ...commLink.current,
                type: 'sensors_from_network'
            });
            
            // prevent moving forward if incomplete data is returned
            setLoading(false);
            if(!comm_link || !sensors || sensors.length === 0) {
                return;
            }

            // update commLink ref with full comm link object
            commLink.current = CommLink.create(comm_link);
            onUpdateTarget({ comm_link: commLink.current });

            // pair sensor totals with products using omnishield_id, group ac sensors in with their non-ac counterparts
            let values = products.map(product => {
                product.sensor_totals = sensors.filter(sensor => {
                    if(!sensor.type) {
                        return false;
                    }
                    switch(sensor.type.code) {
                        case CommLink.Sensor.types.get().ac_smoke:
                        return CommLink.Sensor.types.get().smoke === product.omnishield_id;

                        case CommLink.Sensor.types.get().ac_smoke_co:
                        return CommLink.Sensor.types.get().smoke_co === product.omnishield_id;

                        default:
                        return sensor.type && sensor.type.code === product.omnishield_id;
                    }
                }).length;
                return product;
            }, {});

            // prevent moving forward if user has not made any selections for product totals
            if(!card.units || Object.keys(card.units).length === 0) {
                onAutofillSensorTotals(comm_link, values);
                return;
            }

            // check if at least one value does not match user defined totals
            let mismatches = values.filter(product => {
                return product.sensor_totals > 0 && product.sensor_totals !== card.units[product.key];
            });
            if(mismatches.length > 0) {
                onAutofillSensorTotals(comm_link, values);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this comm link. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateTarget = async props => {
        try {
            let edits = await abstract.object.set(props);
            setCard(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the information for this card. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        return [{
            key: 'graci_support_content',
            items: ['aft.new_protection']
        },{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onDoneClick,
            text: isNewTarget ? 'Done' : 'Save Changes'
        }]
    }

    const getOwnershipFields = () => {

        // prepare sold by user fields for standard user lookups
        let fields = [];

        // set a standard dealership lookup field for directors and administrators if applicable
        if(utils.user.get().level < User.levels.get().dealer) {
            fields = [{
                key: 'dealership',
                title: 'Dealership',
                description: 'Which dealership does this protection belong to?',
                value: card.dealership,
                component: 'dealership_lookup',
                onChange: dealership => onUpdateTarget({ dealership: dealership })
            }];
        }

        // set a list field with dealership registrations if applicable
        if(dealershipRegistrations.length > 0) {
            fields = [{
                key: 'dealership',
                title: 'Dealership',
                description: 'Which dealership does this protection belong to?',
                value: card.dealership && dealershipRegistrations.find(item => item.id === card.dealership.id),
                component: 'list',
                items: dealershipRegistrations,
                onChange: item => {
                    onUpdateTarget({ 
                        dealership: item && {
                            external: item.external,
                            id: item.id,
                            name: item.title
                        },
                        sold_by: item && item.external ? utils.user.get() : null
                    })
                }
            }];
        }

        // add a fixed field with a preselected sold_by_user for users who are submitting for external dealerships
        if(dealershipRegistrations.length > 0 && card.dealership && card.dealership.id !== utils.dealership.get().id) {
            fields.push({
                key: 'sold_by',
                title: 'Sold By',
                description: 'This is the user who will receive credit, in addition to the dealership, for this protection',
                visible: card.dealership ? true : false,
                value: card.sold_by && card.sold_by.full_name,
                component: 'textfield',
                props: {
                    disabled: true
                }
            });
        } else {
            fields.push({
                key: 'sold_by',
                title: 'Sold By',
                description: 'This is the user who will receive credit, in addition to the dealership, for this protection',
                visible: card.dealership ? true : false,
                value: card.sold_by,
                component: 'user_lookup',
                onChange: user => onUpdateTarget({ sold_by: user }),
                props: {
                    dealership: card.dealership,
                    restrictToDealership: true,
                    showExternalRegistrations: true
                }
            })
        }

        return fields;
    }

    const getFields = () => {
        if(!card) {
            return [];
        }
        return [{
            key: 'customer',
            title: 'Customer',
            items: [{
                key: 'first_name',
                title: 'First Name',
                description: 'The first name is used when identifying this customer within your Dealership.',
                value: card.customer ? card.customer.first_name : null,
                component: 'textfield',
                props: {
                    icon: 'user'
                },
                onChange: text => {
                    onUpdateTarget({
                        customer: update(card.customer, {
                            first_name: {
                                $set: text
                            },
                            full_name: {
                                $set: `${text} ${card.customer.last_name}`
                            }
                        })
                    })
                }
            },{
                key: 'last_name',
                title: 'Last Name',
                description: 'The last name is used when identifying this customer within your Dealership.',
                value: card.customer ? card.customer.last_name : null,
                component: 'textfield',
                props: {
                    icon: 'user'
                },
                onChange: text => {
                    onUpdateTarget({
                        customer: update(card.customer, {
                            last_name: {
                                $set: text
                            },
                            full_name: {
                                $set: `${card.customer.first_name} ${text}`
                            }
                        })
                    })
                }
            },{
                key: 'spouse',
                required: false,
                title: 'Spouse Name',
                description: 'Adding a spouse name gives us a more accurate look at this customer and their family.',
                value: card.customer ? card.customer.spouse : null,
                component: 'textfield',
                props: {
                    icon: 'spouse'
                },
                onChange: text => {
                    onUpdateTarget({
                        customer: update(card.customer, {
                            spouse: {
                                $set: text
                            }
                        })
                    })
                }
            },{
                key: 'phone_number',
                title: 'Phone Number',
                description: 'Adding a phone number allows us to provide and marketing related features to your Dealership.',
                value: card.customer ? card.customer.phone_number : null,
                component: 'textfield',
                props: {
                    icon: 'phone',
                    format: 'phone_number'
                },
                onChange: text => {
                    onUpdateTarget({
                        customer: update(card.customer, {
                            phone_number: {
                                $set: text
                            }
                        })
                    })
                }
            },{
                key: 'email_address',
                title: 'Email Address',
                description: 'Adding an email address allows us to provide marketing related features to your Dealership.',
                value: card.customer ? card.customer.email_address : null,
                component: 'textfield',
                props: {
                    icon: 'email'
                },
                onChange: text => {
                    onUpdateTarget({
                        customer: update(card.customer, {
                            email_address: {
                                $set: text
                            }
                        })
                    })
                }
            },{
                key: 'address',
                title: 'Installation Address',
                description: 'This address should be the address of the location where the installation will take place.',
                value: card.customer && card.customer.address && {
                    address: card.customer.address,
                    location: card.customer.location
                },
                component: 'address_lookup',
                onChange: response => {
                    onUpdateTarget({
                        customer: {
                            ...card.customer,
                            ...response
                        }
                    });
                }
            }]
        },{
            key: 'details',
            title: 'About this Protection',
            items: getOwnershipFields().concat([{
                key: 'date',
                title: 'Date Sold',
                description: 'When was this protection sold? We use this date for reporting purposes.',
                value: card.date,
                component: 'date_time_picker',
                onChange: date => onUpdateTarget({ date: date }),
                props: {
                    maxDate: Utils.conformDate(null, 5)
                }
            },{
                key: 'lead',
                required: false,
                title: 'Lead',
                description: `Did this protection originate from a Lead in Global Data? If so, please search for the Lead below. Otherwise you can leave this field blank.`,
                value: card.lead,
                component: 'lead_lookup',
                onChange: lead => onUpdateTarget({ lead: lead })
            }])
        },{
            key: 'comm_link',
            title: 'Comm Link',
            items: [{
                key: 'serial_number',
                required: false,
                loading: loading === 'serial_number',
                title: 'Serial Number',
                description: `Providing a serial number for the customer's Comm-Link allows you to monitor the status and overall health of their home safe network.`,
                value: card.comm_link && card.comm_link.serial_number,
                component: 'textfield',
                onChange: text => onUpdateCommLink('serial_number', text),
                props: {
                    useDelay: true
                }
            },{
                key: 'security_key',
                required: false,
                loading: loading === 'security_key',
                title: 'Security Key',
                description: `Providing a security key for the customer's Comm-Link allows you to monitor the status and overall health of their home safe network.`,
                value: card.comm_link && card.comm_link.security_key,
                component: 'textfield',
                onChange: text => onUpdateCommLink('security_key', text),
                props: {
                    autoCapitalize: 'characters',
                    useDelay: true
                }
            }]
        },{
            key: 'units',
            title: 'Products',
            items: products.map(product => ({
                key: product.key,
                title: product.name,
                description: `Please enter the total units sold for "${product.name}". You can leave this field blank or enter 0 if you did not sell any units.`,
                value: card.units ? `${card.units[product.key] || 0}` : '0',
                component: 'textfield',
                onValidate: text => text && parseInt(text) > 0,
                props: {
                    format: 'integer'
                },
                onChange: int => {
                    onUpdateTarget({
                        units: update(card.units, {
                            [product.key]: {
                                $set: isNaN(int) ? 0 : int
                            }
                        })
                    })
                }
            })).concat([{
                key: 'full_protection',
                required: false,
                title: 'Full Protection',
                description: `Would you consider this protection's customer as fully protected?`,
                value: card.full_protection,
                component: 'bool_list',
                onChange: enabled => onUpdateTarget({ full_protection: enabled })
            }])
        },{
            key: 'ext_details',
            title: 'Additional Information',
            items: [/*{
                key: 'tags',
                required: false,
                title: 'Tags',
                description: 'Attaching one or more tags to a lead can help organize your Deealership\'s lead catalog. Type a word and press enter when you are done',
                value: card.tags,
                component: 'tag_lookup',
                onChange: tags => onUpdateTarget({ tags: tags })
            },*/{
                key: 'comments',
                required: false,
                title: 'Comments',
                description: 'Comments are optional and are not visible by the customer. Use this area to add any information that has not already been covered in the fields above.',
                value: card.comments,
                component: 'textview',
                onChange: text => onUpdateTarget({ comments: text })
            }]
        }]
    }

    const requestDealershipMismatchConfirmation = () => {
        return new Promise((resolve, reject) => {
            utils.alert.show({
                title: `Submitting for ${card.dealership.name}`,
                message: `It looks like you are logged into ${utils.dealership.get().name} but you are submitting this protection to ${card.dealership.name}. Are you sure that you want to submit this protection to ${card.dealership.name}?`,
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'confirm') {
                        resolve();
                        return;
                    }
                    let error = new Error('user_cancelled');
                    reject(error);
                }
            })
        });
    }

    const setupTarget = async () => {
        try {

            // fetch products list
            let { products } = await Request.get(utils, '/products/', {
                type: 'all'
            });
            setProducts(products.map(product => Product.create(product)));

            // open editing for target and set dealership if applicable
            let edits = await abstract.object.open();
            if(isNewTarget) {
                edits = await abstract.object.set({ dealership: utils.dealership.get() });
            }

            // fetch external dealership registrations
            let { registrations } = await Request.get(utils, '/users/', {
                type: 'user_external_dealership_registrations',
                user_id: abstract.object.sold_by && abstract.object.sold_by.user_id
            });

            // prepare list items for dealership registrations if applicable
            if(registrations.length > 0) {
                
                let dealership = utils.dealership.get();
                let items = registrations.map(registration => ({
                    external: true,
                    id: registration.dealership.id,
                    title: registration.dealership.name
                })).concat([{
                    id: dealership.id,
                    title: dealership.name
                }]).sort((a,b) => {
                    return a.title.localeCompare(b.title);
                });
                setDealershipRegistrations(items);
            }

            // update local state with edits
            setLoading(false);
            setCard(edits);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the ${isNewTarget ? 'new protection' : 'editing'} process. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        abstract={abstract}
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? 'New Protection' : `Editing ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const CardDetails = ({ abstract, index, options, utils }) => {

    const layerID = `card_details_${abstract.getID()}`;
    const [card, setCard] = useState(abstract.object);
    const [commLink, setCommLink] = useState(abstract.object.comm_link);
    const [layerState, setLayerState] = useState(null);
    const [lead, setLead] = useState(null);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(abstract.object.customer.location);
    const [notificationOptOut, setNotificationOptOut] = useState(null);

    const onCommLinkClick = () => {
        utils.layer.open({
            id: `comm_link_details_${commLink.id}`,
            abstract: Abstract.create({
                type: 'comm_link',
                object: commLink
            }),
            Component: CommLinkDetails
        })
    }

    const onDealershipClick = () => {
        utils.layer.open({
            id: `dealership_details_${abstract.object.dealership.id}`,
            abstract: Abstract.create({
                type: 'dealership',
                object: abstract.object.dealership
            }),
            Component: DealershipDetails
        })
    }

    const onDeleteCard = () => {
        utils.alert.show({
            title: 'Delete Protection',
            message: 'Are you sure that you want to delete this protection? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteCardConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteCardConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/cards/', {
                type: 'delete',
                id: abstract.getID()
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This protection has been deleted from the "${abstract.object.dealership.name}" dealership.`,
                onClick: () => {
                    setLayerState('close');
                    utils.content.fetch('card');
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this protection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_card_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditCard.bind(this, {
                isNewTarget: false
            })
        })
    }

    const onLeadClick = async () => {
        try {
            let { active } = await utils.sockets.requestGDLAccess(utils.user.get().user_id);
            if(active === true) {
                await utils.sockets.emit('aft', 'global_data', 'show', {
                    type: 'lead',
                    id: lead.id
                });
                utils.alert.show({
                    title: 'Global Data',
                    message: `We've sent this lead to Global Data. You can view it by navigating to your Global Data browser window`
                })
                return;
            }
            utils.alert.show({
                title: 'Global Data',
                message: `We'll need to direct you to Global Data to view this information. Would you like to open a window for Global Data?`,
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Maybe Later',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'confirm') {
                        window.open(`${API.external.gdl.server}/?lead_id=${lead.id}`);
                        return;
                    }
                }
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue communicating with Global Data. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNotificationOptOutClick = () => {
        utils.alert.show({
            title: 'Notification Opt Out',
            message: `This customer opted out of receiving marketing and promotional notifications on ${moment(notificationOptOut.date).format('MMMM Do, YYYY [at] h:mma')}. Customers can opt out of receiving this content by clicking the "Unsubscribe" link in an Applied Fire Technologies email.`
        })
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'delete',
                title: 'Delete',
                visible: canDeleteCard(),
                style: 'destructive'
            },{
                key: 'resend_invoice',
                title: 'Resend Invoice',
                style: 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'delete') {
                onDeleteCard();
                return;
            }
            if(key === 'resend_invoice') {
                onResendInvoice();
                return;
            }
        });
    }

    const onResendInvoice = () => {
        utils.alert.show({
            title: 'Resend Invoice',
            message: abstract.object.customer.email_address ? 'Please verify the email address below before resending the invoice for this protection.' : 'Please provide an email address below where we can send the protection invoice.',
            textFields: [{
                key: 'email_address',
                placeholder: 'Email Address',
                value: abstract.object.customer.email_address
            }],
            buttons: [{
                key: 'send',
                title: 'Send',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Send',
                style: 'cancel'
            }],
            onClick: ({ email_address, key }) => {
                if(email_address && key === 'send') {
                    onResendInvoiceConfirm(email_address);
                    return;
                }
            }
        })
    }

    const onResendInvoiceConfirm = async emailAddress => {
        try {
            setLoading('options');
            await Utils.sleep(1);
            await Request.post(utils, '/cards/', {
                type: 'send_invoice',
                id: abstract.getID(),
                email_address: emailAddress
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The invoice for this protection has been sent to "${emailAddress}"`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue resending the invoice for this protection. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateCard = data => {
        try {
            if(abstract.getID() == data.card.id) {
                abstract.object = Card.create(data.card);
                setCard(abstract.object);
            }
        } catch(e) {
            console.log(e.message);
        }
    }

    const onUserClick = async userID => {
        try {
            let user = await User.get(utils, userID);
            utils.layer.open({
                id: `user_details_${user.user_id}`,
                abstract: Abstract.create({
                    type: 'user',
                    object: user
                }),
                Component: UserDetails
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the account information for this user. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        return [{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            laoding: loading=== 'options',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }];
    }

    const getCommLink = () => {
        return commLink && (
            <LayerItem title={'Comm Link'}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {Views.entry({
                        bottomBorder: false,
                        icon: {
                            path: commLink.online && commLink.online.status ? 'images/online-icon.png' : 'images/offline-icon.png',
                            style: {
                                backgroundColor: null,
                                border: 'none',
                                borderRadius: 32.5
                            }
                        },
                        onClick: onCommLinkClick,
                        subTitle: commLink.address && commLink.address.street_address_1 || 'Address Not Available',
                        title: commLink.name
                    })}
                </div>
            </LayerItem>
        )
    }

    const getFields = () => {
        if(!card) {
            return [];
        }
        let items = [{
            key: 'customer',
            title: 'Customer',
            items: [{
                key: 'email_address',
                title: 'Email Address',
                value: card.customer.email_address
            },{
                key: 'first_name',
                title: 'First Name',
                value: card.customer.first_name
            },{
                key: 'last_name',
                title: 'Last Name',
                value: card.customer.last_name
            },{
                key: 'phone_number',
                title: 'Phone Number',
                value: card.customer.phone_number
            },{
                key: 'spouse',
                title: 'Spouse',
                value: card.customer.spouse
            }]
        },{
            key: 'location',
            title: 'Location',
            visible: card.customer.address && location ? true : false,
            items: [{
                key: 'location',
                title: 'Location',
                component: 'map',
                value: location
            },{
                key: 'address',
                title: 'Address',
                value: Utils.formatAddress(card.customer.address)
            },{
                key: 'maps',
                onClick: () => {
                    let address = Utils.formatAddress(card.customer.address);
                    window.open(`https://www.google.com/maps/place/${encodeURIComponent(address)}`)
                },
                title: 'Directions',
                value: 'Click to View'
            }]
        },{
            key: 'units',
            title: 'Units Sold',
            items: card.units.map(unit => ({
                key: unit.key,
                title: unit.name,
                value: `${unit.total} ${unit.total === 1 ? 'Unit' : 'Units'}`
            }))
        },{
            key: 'details',
            lastItem: false,
            title: 'Details',
            items: [{
                key: 'comments',
                title: 'Comments',
                value: card.comments,
            },{
                key: 'dealership',
                title: 'Dealership',
                ...card.dealership && {
                    value: card.dealership.name,
                    onClick: onDealershipClick
                }
            },{
                key: 'full_protection',
                title: 'Full Protection',
                value: `${card.full_protection ? 'Yes' : 'No'}`
            },{
                key: 'id',
                title: 'ID',
                value: card.id
            },{
                key: 'start_date',
                title: 'Protection Date',
                value: card.date ? moment(card.date).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'sold_by',
                title: 'Sold By',
                ...card.sold_by && {
                    value: card.sold_by.full_name,
                    onClick: onUserClick.bind(this, card.sold_by.user_id)
                }
            },{
                key: 'created',
                title: 'Submitted',
                value: card.created ? moment(card.created).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'tags',
                title: 'Tags',
                value: card.tags ? Utils.oxfordImplode(card.tags.map(tag => tag.text)) : 'Not Added',
            },{
                key: 'total_units',
                title: 'Total Units Sold',
                value: `${card.total_units || 0} ${card.total_units === 1 ? 'Unit' : 'Units'}`
            }]
        }];
        return items;
    }

    const getHeaderBanner = () => {

        let fields = [{
            key: 'notification_opt_out',
            title: 'Opted Out of Notifications',
            value: notificationOptOut ? true : false,
            color: Appearance.colors.red,
            message: 'This customer has opted out of all marketing and promotional notifications',
            onClick: onNotificationOptOutClick
        }];

        if(!fields.find(field => field.value === true)) {
            return null;
        }

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: 12,
                width: '100%'
            }}>
                {fields.filter(field => {
                    return field.value === true;
                }).map((field, index, fields) => (
                    <div
                    key={index}
                    className={'text-button'}
                    onClick={field.onClick}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderRadius: 10,
                        overflow: 'hidden',
                        border: `2px solid ${field.color}`,
                        background: Appearance.colors.softGradient(field.color),
                        marginBottom: index === fields.length - 1 ? 12 : 8,
                        padding: '6px 10px 6px 10px',
                        width: '100%'
                    }}>
                        <img
                        src={'images/alert-icon-white-small.png'}
                        style={{
                            width: 30,
                            height: 30,
                            minWidth: 30,
                            minHeight: 30,
                            marginRight: 8
                        }} />
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title(),
                                color: 'white',
                                fontWeight: 800
                            }}>{field.title}</span>
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                color: 'white',
                                fontWeight: 600
                            }}>{field.message}</span>
                        </div>
                        <img
                        src={'images/next-arrow-white-small.png'}
                        style={{
                            width: 12,
                            height: 12,
                            objectFit: 'contain',
                            marginLeft: 8
                        }} />
                    </div>
                ))}
            </div>
        )
    }

    const getLead = () => {
        if(!lead) {
            return null;
        }
        return (
            <LayerItem title={'Lead'}>
                <div style={Appearance.styles.unstyledPanel()}>
                    {Views.entry({
                        title: lead.full_name,
                        subTitle: lead.phone_number,
                        hideIcon: true,
                        bottomBorder: false,
                        bottomBorder: false,
                        onClick: onLeadClick
                    })}
                </div>
            </LayerItem>
        )
    }

    const getSignature = () => {
        if(!abstract.object.signature) {
            return null;
        }
        return (
            <LayerItem title={'Customer Signature'}>
                <div style={Appearance.styles.unstyledPanel()}>
                    <img
                    src={abstract.object.signature}
                    style={{
                        width: '100%',
                        height: 100,
                        objectFit: 'contain'
                    }} />
                </div>
            </LayerItem>
        )
    }

    const canDeleteCard = () => {
        if(utils.user.get().level <= User.levels.get().admin) {
            return true;
        }
        if(utils.user.get().level <= User.levels.get().dealer && utils.user.get().dealership_id === abstract.object.dealership.id) {
            return true;
        }
        return false;
    }

    const fetchDetails = async () => {
        try {
            let { comm_link, lead, location, notification_opt_out } = await Request.get(utils, '/cards/', {
                type: 'ext_details',
                id: abstract.getID()
            });

            // set comm link if applicable
            if(comm_link) {
                setCommLink(CommLink.create(comm_link));
            }

            // set location if applicable
            if(location) {
                abstract.object.customer.location = {
                    latitude: location.lat,
                    longitude: location.long
                }
                setLocation(abstract.object.customer.location);
            }

            // set lead details if applicable
            if(lead) {
                abstract.object.lead = lead;
                setLead(lead);
            }

            // set notification opt out information if applicable
            setNotificationOptOut(notification_opt_out);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving some of the information for this protection. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const removeSocketSubscription = async () => {
        try {
            await utils.sockets.off('aft', 'cards', 'on_update_card', onUpdateCard);
        } catch(e) {
            console.error(e.message);
        }
    }

    const setupSocketSubscription = async () => {
        try {
            await utils.sockets.on('aft', 'cards', 'on_update_card', onUpdateCard);
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        fetchDetails();
        setupSocketSubscription();
        utils.content.subscribe(layerID, ['card', 'comm_link'], {
            onUpdate: next => {
                switch(next.type) {
                    case 'card':
                    if(next.getID() === abstract.getID()) {
                        fetchDetails();
                    }
                    break;

                    case 'comm_link':
                    setCommLink(prev => prev.id === abstract.object.id ? abstract.object : prev);
                    break;
                }
            }
        })
        return () => {
            removeSocketSubscription();
            utils.content.unsubscribe(layerID);
        }

    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        title={`${abstract.getTitle()} Details`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            sizing: 'medium'
        }}>
            {getHeaderBanner()}
            {getCommLink()}
            {getLead()}

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            <SystemEventsList
            utils={utils}
            abstract={abstract} />

            {getSignature()}
        </Layer>
    )
}

// Components
export const getTotalUnitsBadge = target => {
    if(target.total_units < 5) {
        return {
            text: `${target.total_units > 0 ? Utils.softNumberFormat(target.total_units) : 0} ${target.total_units === 1 ? 'Unit' : 'Units'}`,
            color: Appearance.colors.grey()
        }
    }
    return {
        text: `${target.total_units > 0 ? Utils.softNumberFormat(target.total_units) : 0} Units`,
        color: Appearance.colors.green
    }
}
