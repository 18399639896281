
import moment from 'moment-timezone';
import Payment from 'classes/Payment.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class DealershipClass {

    access_token = null;
    active = null;
    address = null;
    alias = null;
    avatar = null;
    dealer = null;
    dealer_name = null; // optional list value
    gdl_active = null;
    has_white_label_app = null;
    id = null;
    location = null;
    name = null;
    omnishield = null;
    payments_account = null;
    phone_number = null;
    preferences = {};
    subscriptions = null;
    timezone = null;

    cosntructor() {
        return this;
    }

    apply = (utils, isNewTarget, props) => {
        return isNewTarget ? this.submit(utils) : this.update(utils, props);
    }

    close = async () => {
        return new Promise(resolve => {
            this.address = this.edits.address;
            this.alias = this.edits.alias;
            this.dealer = this.edits.dealer;
            this.gdl_active = this.edits.gdl_active;
            this.location = this.edits.location;
            this.name = this.edits.name;
            this.omnishield = this.edits.omnishield || {};
            this.phone_number = this.edits.phone_number;
            this.timezone = this.edits.timezone;
            resolve();
        });
    }

    create = (props = {}) => {
        this.access_token = props.access_token;
        this.active = props.active;
        this.address = props.address;
        this.alias = props.alias;
        this.avatar = props.avatar;
        this.dealer = props.dealer && User.create(props.dealer);
        this.dealer_name = props.dealer_name;
        this.gdl_active = props.gdl_active;
        this.has_white_label_app = props.has_white_label_app;
        this.id = props.id;
        this.location = props.location && {
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.name = props.name;
        this.omnishield = props.omnishield || {};
        this.payments_account = props.payments_account;
        this.phone_number = props.phone_number;
        this.preferences = props.preferences || [];
        this.subscriptions = props.subscriptions && props.subscriptions.map(subscription => Payment.Subscription.create(subscription)) || [];
        this.timezone = props.timezone;
        return this;
    }

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                address: this.address,
                alias: this.alias,
                dealer: this.dealer,
                gdl_active: this.gdl_active,
                location: this.location,
                name: this.name,
                omnishield: this.omnishield || {},
                phone_number: this.phone_number,
                preferences: this.preferences || [],
                timezone: this.timezone
            }
            resolve(this.edits);
        });
    }

    set = async props => {
        return new Promise(resolve => {
            this.edits = {
                ...this.edits,
                ...props
            }
            resolve(this.edits);
        });
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { access_token, id } = await Request.post(utils, '/dealerships/', {
                    type: 'new',
                    ...this.toJSON(this.edits)
                })

                // set record id and access token
                this.access_token = access_token;
                this.id = id;

                // close edits and notify subscribers of data change
                await this.close();
                utils.content.fetch('dealership');

                resolve({ access_token });

            } catch(e) {
                reject(e);
            }
        });
    }

    toJSON = props => {
        let target = props || this;
        return {
            address: target.address,
            alias: target.alias,
            dealer_user_id: target.dealer && target.dealer.user_id,
            gdl_active: target.gdl_active,
            id: this.id,
            location: target.location,
            name: target.name,
            omnishield: target.omnishield,
            phone_number: target.phone_number,
            preferences: target.preferences,
            timezone: target.timezone
        }
    }

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {

                // submit request to server
                console.log(this.toJSON(this.edits))
                let { change_request_id, nameservers, omnishield_preferences, preferences } = await Request.post(utils, '/dealerships/', {
                    type: 'update',
                    ...this.toJSON(this.edits),
                    ...props
                });

                // close local edits
                await this.close();

                // update preferences and omnishield preferences object
                this.omnishield = omnishield_preferences;
                this.preferences = preferences;

                // notify subscribers of data change
                utils.content.update({
                    object: this,
                    type: 'dealership'
                });

                resolve({ change_request_id, nameservers });

            } catch(e) {
                reject(e);
            }
        });
    }
}

class DealershipUserRegistrationClass {

    active = null;
    date = null;
    dealership = null;
    id = null;
    status = null;
    token = null;
    user = null;

    create = props => {
        this.active = props.active;
        this.date = props.date && moment.utc(props.date).local();
        this.dealership = props.dealership;
        this.id = props.id;
        this.status = props.status;
        this.token = props.token;
        this.user = props.user;
        return this;
    }
}

const registrationStatus = {
    pending: null,
    accepted: 1,
    rejected: 2,
    cancelled: 3
}

export const fetchDealership = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { dealership } = await Request.get(utils, '/dealerships/', {
                type: 'details',
                id: id
            });
            resolve(new DealershipClass().create(dealership));
        } catch(e){
            reject(e);
        }
    });
}

export default {
    create: props => new DealershipClass().create(props),
    get: (utils, id) => fetchDealership(utils, id),
    new: () => new DealershipClass(),
    UserRegistration: {
        create: props => new DealershipUserRegistrationClass().create(props),
        new: () => new DealershipUserRegistrationClass(),
        status: {
            get: () => registrationStatus
        }
    }
}
