import moment from 'moment';
import Dealership from 'classes/Dealership.js';
import Request from 'files/Request.js';

class UserClass {

    active = true;
    address = null;
    avatar = null;
    by_design_id = null;
    created = null;
    dealership = null;
    dealership_id = null;
    email_address = null;
    first_name = null;
    full_name = null;
    gdl_active = null;
    genealogy = null;
    last_login = null;
    last_name = null;
    level = null;
    location = null;
    one_to_one = null;
    phone_number = null;
    token = null;
    recruitment = null;
    refresh_token = null;
    spouse = null;
    tasks = [];
    testcom_account_setup = null;
    timezone = null;
    user_id = null;
    username = null;
    verified = null;
    
    constructor() {
        return this;
    }

	create = (props = {}) => {
        this.active = props.active;
        this.address = props.address;
        this.avatar = props.avatar;
        this.by_design_id = props.by_design_id;
        this.created = props.created && moment(props.created);
        this.dealership = props.dealership && Dealership.create(props.dealership);
        this.dealership_id = props.dealership_id;
        this.email_address = props.email_address;
        this.first_name = props.first_name;
        this.full_name = props.full_name;
        this.gdl_active = props.gdl_active;
        this.genealogy = props.genealogy;
        this.last_login = props.last_login && moment(props.last_login);
        this.last_name = props.last_name;
        this.level = props.level;
        this.location = props.location && {
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.one_to_one = props.one_to_one;
        this.phone_number = props.phone_number;
        this.recruitment = props.recruitment;
        this.refresh_token = props.refresh_token;
        this.spouse = props.spouse;
        this.tasks = props.tasks;
        this.testcom_account_setup = props.testcom_account_setup;
        this.timezone = props.timezone;
        this.token = props.token;
        this.user_id = props.user_id;
        this.username = props.username;
        this.verified = props.verified && moment(props.verified);
        return this;
	}

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                address: this.address,
                by_design_id: this.by_design_id,
                dealer: this.dealer,
                dealership: this.dealership,
                email_address: this.email_address,
                first_name: this.first_name,
                full_name: this.full_name,
                genealogy: this.genealogy || {},
                last_name: this.last_name,
                level: this.level,
                location: this.location,
                one_to_one: this.one_to_one,
                password: this.password,
                phone_number: this.phone_number,
                recruitment: this.recruitment,
                spouse: this.spouse,
                timezone: this.timezone,
                username: this.username
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        return new Promise(resolve => {
            this.edits = {
                ...this.edits,
                ...props
            }
            this.edits.full_name = `${this.edits.first_name} ${this.edits.last_name}`;
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            this.address = this.edits.address;
            this.by_design_id = this.edits.by_design_id;
            this.dealership = this.edits.dealership;
            this.email_address = this.edits.email_address;
            this.first_name = this.edits.first_name;
            this.full_name = this.edits.full_name;
            this.genealogy = this.edits.genealogy;
            this.last_name = this.edits.last_name;
            this.level = this.edits.level || this.level;
            this.location = this.edits.location;
            this.one_to_one = this.edits.one_to_one;
            this.password = this.edits.password;
            this.phone_number = this.edits.phone_number;
            this.recruitment = this.edits.recruitment;
            this.spouse = this.edits.spouse;
            this.timezone = this.edits.timezone;
            this.username = this.edits.username;
            resolve(this);
        })
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { user_id } = await Request.post(utils, '/users/', {
                    type: 'new',
                    ...this.toJSON(this.edits),
                    ...props
                });

                await this.close();
                this.user_id = user_id;
                utils.content.fetch('user');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let fetch = this.level !== this.edits.level;
                await Request.post(utils, '/users/', {
                    type: 'update',
                    user_id: this.user_id,
                    ...this.toJSON(this.edits),
                    ...props
                });
                await this.close();

                if(fetch) {
                    utils.content.fetch('user');
                    resolve();
                    return;
                }
                utils.content.update({
                    type: 'user',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            user_id: this.user_id,
            by_design_id: target.by_design_id,
            username: target.username,
            password: target.password,
            first_name: target.first_name,
            last_name: target.last_name,
            full_name: target.full_name,
            spouse: target.spouse,
            level: target.level,
            address: target.address,
            location: target.location && {
                lat: target.location.latitude,
                long: target.location.longitude
            },
            email_address: target.email_address,
            phone_number: target.phone_number,
            dealership_id: target.dealership ? target.dealership.id : null,
            recruitment: target.recruitment,
            genealogy: target.genealogy,
            one_to_one: target.one_to_one,
            timezone: target.timezone
        }
    }
}

class UserGroupClass {

    id = null;
    dealership = null;
    title = null;
    description = null;
    date = null;
    group_type = null;
    category = null;
    user_ids = null;
    users = []; // internal use only
    levels = [];
    props = {};
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.dealership = props.dealership ? Dealership.create(props.dealership) : null;
        this.title = props.title;
        this.description = props.description;
        this.date = props.date;
        this.group_type = props.group_type;
        this.category = props.category;
        this.user_ids = props.user_ids;
        this.levels = props.levels;
        this.props = props.props;
        this.active = props.active;
        return this;
    }

    open = async () => {
        return new Promise(resolve => {
            this.edits = {
                dealership: this.dealership,
                title: this.title,
                description: this.description,
                group_type: this.group_type,
                category: this.category,
                users: this.users,
                levels: this.levels || [],
                props: this.props || {}
            }
            resolve(this.edits);
        })
    }

    set = async props => {
        return new Promise(resolve => {
            this.edits = {
                dealership: props.dealership || this.edits.dealership,
                title: props.title || this.edits.title,
                description: props.description || this.edits.description,
                group_type: props.group_type || this.edits.group_type,
                category: props.category || this.edits.category,
                users: props.users || this.edits.users,
                levels: props.levels || this.edits.levels,
                props: props.props || this.edits.props
            }
            resolve(this.edits);
        })
    }

    close = async () => {
        return new Promise(resolve => {
            this.category = this.edits.category;
            this.dealership = this.edits.dealership;
            this.description = this.edits.description;
            this.group_type = this.edits.group_type;
            this.levels = this.edits.levels;
            this.props = this.edits.props;
            this.title = this.edits.title;
            this.users = this.edits.users;
            resolve(this);
        })
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { category, id } = await Request.post(utils, '/users/', {
                    type: 'new_group',
                    ...this.toJSON(this.edits)
                });
                await this.close();
                this.id = id;
                this.category = category;
                utils.content.fetch('user_group');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/users/', {
                    type: 'update_group',
                    id: this.id,
                    ...this.toJSON(this.edits)
                });
                await this.close();

                utils.content.update({
                    type: 'user_group',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = props => {
        let target = props || this;
        return {
            dealership_id: target.dealership ? target.dealership.id : null,
            title: target.title,
            description: target.description,
            group_type: target.group_type ? target.group_type.code : null,
            category: this.id ? target.category.code : target.category,
            user_ids: target.users ? target.users.map(user => user.user_id) : target.user_ids,
            levels: target.levels,
            props: target.props
        }
    }
}

export const fetchUser = async (utils, userID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { user } = await Request.get(utils, '/users/', {
                type: 'details',
                user_id: userID
            });
            resolve(new UserClass().create(user));
        } catch(e){
            reject(e);
        }
    });
}

const levels = {
    system_admin: 1,
    admin: 2,
    exigent_admin: 2000,
    region_director: 3000,
    division_director: 3250,
    area_director: 3500,
    vested_director: 3750,
    dealer: 4000,
    booking_coordinator: 4250,
    marketing_director: 4251,
    safety_advisor: 5000,
    omnishield_installer: 6000,
    safety_associate: 7000,
    trainee: 8000,
    dreamcatcher: 9900
}

const levelToText = code => {
    switch(code) {
        case levels.system_admin:
        return 'System Administrator';

        case levels.admin:
        return 'Administrator';

        case levels.exigent_admin:
        return 'Exigent Administrator';

        case levels.region_director:
        return 'Region Director';

        case levels.division_director:
        return 'Division Director';

        case levels.area_director:
        return 'Area Director';

        case levels.vested_director:
        return 'Vested Director';

        case levels.dealer:
        return 'Dealer';

        case levels.booking_coordinator:
        return 'Booking Coordinator';

        case levels.marketing_director:
        return 'Marketing Director';

        case levels.safety_advisor:
        return 'Safety Advisor';

        case levels.omnishield_installer:
        return 'OmniShield Installer';

        case levels.safety_associate:
        return 'Safety Associate';

        case levels.trainee:
        return 'Trainee';

        case levels.dreamcatcher:
        return 'DreamCatcher';

        default:
        return 'Unknown account type';
    }
}

export default {
    new: () => new UserClass(),
    get: fetchUser,
    create: props => new UserClass().create(props),
    levels: {
        get: () => levels,
        toText: levelToText
    },
    Group: {
        new: () => new UserGroupClass(),
        create: props => new UserGroupClass().create(props),
        types: {
            levels: 1,
            user_ids: 2
        },
        categories: {
            demos: 1,
            demo_requests: 2,
            leads: 3,
            users: 4,
            protections: 5
        }
    }
};
