import moment from 'moment-timezone';
import Dealership from 'classes/Dealership.js';
import Request from 'files/Request.js';

class ChangeRequest {

    data = null;
    date = null;
    dealership = null;
    events = [];
    id = null;
    status = null;
    user_id = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.data = props.data || {};
        this.date = props.date && moment(props.date);
        this.dealership = props.dealership && Dealership.create(props.dealership);
        this.events = props.events || [];
        this.id = props.id;
        this.status = props.status;
        this.user_id = props.user_id;
        return this;
    }
}

const fetchChangeRequest = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { request } = await Request.get(utils, '/omnishield/', {
                id: id,
                type: 'white_label_change_request_details'
            });
            resolve(new ChangeRequest().create(request));

        } catch(e) {
            reject(e);
        }
    });
}

const changeRequestStatusCodes = {
    pending: 1,
    approved: 2,
    rejected: 3,
    cancelled: 4
}

const changeRequestStatusCodeToText = code => {
    switch(code) {
        case changeRequestStatusCodes.pending:
        return 'Pending';

        case changeRequestStatusCodes.approved:
        return 'Approved';

        case changeRequestStatusCodes.rejected:
        return 'Rejected';

        case changeRequestStatusCodes.cancelled:
        return 'Cancelled';

        default:
        return 'Unknown status';
    }
}

const appReviewStatus = {
    requesting_capabilities: 1,
    preparing_for_submission: 2,
    waiting_for_review: 3,
    in_review: 4,
    approved: 5,
    rejected: 6,
    cancelled: 7,
    ready_for_distribution: 8,
    awaiting_configuration: 9
}

export default {
    app_review_status: {
        get: () => appReviewStatus
    },
    ChangeRequest: {
        create: props => new ChangeRequest().create(props),
        get: fetchChangeRequest,
        new: () => new ChangeRequest(),
        status: {
            get: () => changeRequestStatusCodes,
            toText: changeRequestStatusCodeToText
        }
    }
};
